@import 'variables';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/700.css';
@import '@fontsource/roboto-condensed/700.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button {
  background-color: $grey;
  color: $black;
  padding: $padding;
  border-radius: $radius;
  outline: none;
  border: none;
  font-family: inherit;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 0.05em;
  text-decoration: none;

  &.primary {
    background-color: $yellow;
  }

  &.black {
    background-color: black;
    color: white;
  }

  &.large {
    font-size: 800;
    font-size: 19px;
  }
}

.header {
  background: $black;
  font-size: 25px;
  padding: 18px;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
}

.container {
  width: 1240px;
  padding: 0 20px;
  margin: 0 auto;
}

.flex {
  display: flex;

  &.align-center {
    align-items: center;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-between {
    justify-content: space-between;
  }
}
